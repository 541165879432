import { fetchTranslation, hasTranslation } from 'common/locale';
import {
  QueryAnalysisSucceeded,
  OutputColumn,
  QueryCompilationResult,
  QueryCompilationSucceeded,
  TableAliases,
  ViewContext
} from 'common/types/compiler';
import {
  AnalyzedSelectedExpression,
  ColumnRef,
  distinctIsDistinctOn,
  Expr,
  FunCall,
  FunSpec,
  isColumnEqualIgnoringPosition,
  isColumnRef,
  isExpressionEqualIgnoringPosition,
  isFixed as isFixedFunType,
  isFunCall,
  isParameter,
  isTypedColumnRef,
  isTypedFunCall,
  isTypedNullLiteral,
  mapExpr,
  NamedExpr,
  NoPosition,
  nullLiteral,
  OrderBy,
  Parameter,
  Position,
  Scope,
  Selection as AstSelection,
  SoQLFunCall,
  SoQLType,
  Type,
  TypedExpr,
  TypedSoQLColumnRef,
  TypedFunCall,
  TypedSelect,
  TypedSoQLFunCall,
  TypedSoQLLiteral,
  TypedSoQLStringLiteral,
  UnAnalyzedAst,
  UnAnalyzedJoin,
  UnAnalyzedSelectedExpression,
  UnAnalyzedSelection,
  usingNBEName,
  typedNullLiteral
} from 'common/types/soql';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { compilationSuccess, lastInChain, ProjectionInfo, ProjectionInfoNA } from './selectors';
import { EditableExpression, Eexpr, EexprNA, UnEditableExpression, UnEditableExpressionNA } from '../types';
import { none, None, option, Option, some, Some } from 'ts-option';
import { Either } from 'common/either';

function viewFromQualifier(ctx: ViewContext, qualifier: string | null): Option<View> {
  if (qualifier) {
    return option(ctx[qualifier]);
  } else {
    return option(ctx._);
  }
}

export function fourfourFromColumn(viewContext: ViewContext, cref: ColumnRef): Option<string> {
  return viewFromQualifier(viewContext, cref.qualifier).map((view) => view.id);
}

export function getFunSpec(typed: TypedSoQLFunCall, scope: Scope): Option<FunSpec> {
  return option(scope.find((fs) => fs.identity === typed.function_name));
}

// this is a * aware zip function, for mapping an analyzed (and expanded) selection back to an unanalyzed
// one. we need to send unanalyzed exprs to the soql compiler, so for components like the column reordering
// (and more) UI, they need to work with the expanded and analyzed selection, since they need to be able to
// enumerate all the columns (not just * or @whatever.*), but they also need to build an unanalyzed selection
// from the analyzed form
export function zipSelection(
  viewContext: ViewContext,
  tableAliases: TableAliases,
  unanalyzed: UnAnalyzedSelection,
  analyzed: AnalyzedSelectedExpression[]
): UnAnalyzedSelection {
  const aliasToContextKey = (q: string | null): string | null => {
    if (q) {
      return tableAliases.realTables[q];
    }
    return q;
  };
  const starSelections: ColumnRef[] = unanalyzed.all_user_except.flatMap((aue) =>
    // if the qualifier is wrong, we need to throw an exception here rather than generating an invalid selection
    viewFromQualifier(viewContext, aliasToContextKey(aue.qualifier))
      .get.columns.filter((viewColumn) => !viewColumn.flags?.includes('hidden'))
      .map((viewColumn) => ({
        type: 'column_ref',
        value: viewColumn.fieldName,
        qualifier: aue.qualifier
      }))
  );

  const explicitSelections = _.cloneDeep(unanalyzed.exprs);

  const exprs: UnAnalyzedSelectedExpression[] = analyzed.flatMap((se) => {
    const expr = se.expr;
    if (isTypedColumnRef(expr)) {
      const star = starSelections.find((starRef) => isColumnEqualIgnoringPosition(starRef, expr));
      if (star) {
        return [{ name: null, expr: star }];
      }
    }
    return option(explicitSelections.shift())
      .map((e) => [e])
      .getOrElseValue([]);
  });

  return {
    all_system_except: null,
    all_user_except: [],
    exprs
  };
}

export interface SelectedColumn {
  provenance: Some<{
    view: View;
    column: ViewColumn;
  }>;
  expr: TypedSoQLColumnRef;
  schemaEntry: OutputColumn;
  hasAlias: boolean;
  position?: Position;
}
export interface SystemColumn {
  provenance: Some<{
    view: View;
    column: null;
  }>;
  expr: TypedSoQLColumnRef;
  schemaEntry: OutputColumn;
  hasAlias: boolean;
  position?: Position;
}
export interface CalculatedColumn {
  provenance: None<never>;
  expr: TypedExpr;
  schemaEntry: OutputColumn;
  hasAlias: boolean;
  position?: Position;
}
export type SelectionItem = SelectedColumn | CalculatedColumn | SystemColumn;
export const isSelectedColumn = (i: SelectionItem): i is SelectedColumn => i.provenance.filter(p => p.column !== null).isDefined;
export const isSystemColumn = (i: SelectionItem): i is SystemColumn => i.provenance.isDefined && i.provenance.filter(p => p.column !== null).isEmpty;
export const isCalculatedColumn = (i: SelectionItem): i is CalculatedColumn => i.provenance.isEmpty;
export const selectionWithProvenance = (selection: AstSelection, outputSchema: OutputColumn[], tableAliases: TableAliases, views: ViewContext): SelectionItem[] => {
  const selections: SelectionItem[] = [];
  let cursor = 0;

  // The entries for :* will always be first, and we're handling exceptions by ignoring anything not present in the outputSchema.
  if (selection.all_system_except) {
    const systemStar = selection.all_system_except;
    const alias = option(systemStar.qualifier).map((q) => tableAliases.realTables[q]);
    const view = views[alias.getOrElseValue('_')];
    while (outputSchema[cursor] && outputSchema[cursor].name[0] === ':') {
      const schemaEntry = outputSchema[cursor];
      if (schemaEntry.type) {
        const expr: TypedSoQLColumnRef = {
          type: 'column_ref',
          qualifier: alias.orNull,
          value: schemaEntry.name,
          soql_type: schemaEntry.type
        };
        selections.push({
          provenance: some({ view, column: null }),
          expr,
          schemaEntry,
          hasAlias: false
        } as SystemColumn);
      }
      cursor++;
    }
  }

  selection.all_user_except.forEach((userStar) => {
    const alias = option(userStar.qualifier).map((q) => tableAliases.realTables[q]);
    const view = views[alias.getOrElseValue('_')];
    view.columns
      .filter((column) => !(column.flags || []).includes('hidden'))
      .filter((column) => !userStar.exceptions.some(exc => exc.value === column.fieldName))
      .forEach((column) => {
        const schemaEntry = outputSchema[cursor];
        const { name, type } = schemaEntry;
        if (name !== column.fieldName || type !== column.dataTypeName) {
          throw new Error('mismatch between expected outputColumn and found view column');
        }

        const expr: TypedSoQLColumnRef = {
          type: 'column_ref',
          value: column.fieldName,
          qualifier: alias.orNull,
          soql_type: column.dataTypeName
        };

        selections.push({
          provenance: some({ view, column }),
          expr,
          schemaEntry,
          hasAlias: false
        } as SelectedColumn);

        cursor++;
      });
  });

  selection.exprs.forEach((namedExpr) => {
    const { expr } = namedExpr;
    const schemaEntry = outputSchema[cursor];

    if (isColumnRef(expr)) {
      const alias = option(expr.qualifier).map((q) => tableAliases.realTables[q]);
      const view = views[alias.getOrElseValue('_')];
      const viewColumn = view.columns.find((column) => column.fieldName === expr.value);
      if (viewColumn) {
        selections.push({
          provenance: some({ view, column: viewColumn }),
          expr,
          schemaEntry,
          hasAlias: !!namedExpr.name,
          position: namedExpr.name?.position
        } as SelectedColumn);
      } else if (expr.value[0] === ':') {
        selections.push({
          provenance: some({ view, column: null }),
          expr,
          schemaEntry,
          hasAlias: !!namedExpr.name,
          position: namedExpr.name?.position
        } as SystemColumn);
      } else {
        // This case happens when the query has a pipe (|>). For example,
        // `SELECT 1 + 1 as two |> SELECT two`. I can't think of another way for
        // this case to come up.
        selections.push({
          // This is because `none` is defined as None<never>.
          provenance: none,
          expr,
          schemaEntry,
          hasAlias: !!namedExpr.name,
          position: namedExpr.name?.position
        } as CalculatedColumn);
      }
    } else {
      selections.push({
        // This is because `none` is defined as None<never>.
        provenance: none,
        expr,
        schemaEntry,
        hasAlias: !!namedExpr.name,
        position: namedExpr.name?.position
      } as CalculatedColumn);
    }
    cursor++;
  });

  return selections;
};

export function selectionWithProvenanceFromQueryAnalysis(analysis: QueryAnalysisSucceeded): SelectionItem[] {
  const outputSchema = analysis.outputSchema.filter((entry) => !entry.is_synthetic);
  const { selection } = lastInChain(analysis.ast);
  return selectionWithProvenance(selection, outputSchema, analysis.tableAliases, analysis.views);
}

export const convertSelectionItemToNamedExpr = (selectionItem: SelectionItem): NamedExpr => ({
  expr: selectionItem.expr,
  name: selectionItem.hasAlias ? {
    name: selectionItem.schemaEntry.name,
    position: selectionItem.position || NoPosition
  } : null
});

export const convertSelectionItemsToNamedExprs = (selectionItems: SelectionItem[]): NamedExpr[] =>
  selectionItems.map(convertSelectionItemToNamedExpr);

type LiteralAble =
  | SoQLType.SoQLTextT
  | SoQLType.SoQLNumberT
  | SoQLType.SoQLBooleanT
  | SoQLType.SoQLBooleanAltT;

export function emptyLiteralOfType(st: LiteralAble): TypedSoQLLiteral {
  if (st === SoQLType.SoQLTextT) return { type: 'string_literal', value: '', soql_type: st };
  if (st === SoQLType.SoQLNumberT) return { type: 'number_literal', value: '0', soql_type: st };
  if (st === SoQLType.SoQLBooleanT) return { type: 'boolean_literal', value: true, soql_type: st };
  if (st === SoQLType.SoQLBooleanAltT) return { type: 'boolean_literal', value: true, soql_type: st };
  return typedNullLiteral;
}

export function emptyExprOfType(st: SoQLType): TypedExpr {
  if (
    st === SoQLType.SoQLTextT ||
    st === SoQLType.SoQLNumberT ||
    st === SoQLType.SoQLBooleanT ||
    st === SoQLType.SoQLBooleanAltT
  ) {
    return emptyLiteralOfType(st);
  } else if (st === SoQLType.SoQLFixedTimestampT || st === SoQLType.SoQLFixedTimestampAltT) {
    return castTo(stringLiteral(momentToString(moment.utc(), st).get), SoQLType.SoQLFixedTimestampT);
  } else if (st === SoQLType.SoQLFloatingTimestampT || st === SoQLType.SoQLFloatingTimestampAltT) {
    return castTo(stringLiteral(momentToString(moment(), st).get), SoQLType.SoQLFloatingTimestampT);
  } else if (st === SoQLType.SoQLURLT) {
    return castTo(stringLiteral('https://example.com'), st);
  } else if (st === SoQLType.SoQLLocationT) {
    return castTo(stringLiteral(''), st);
  } else if (st === SoQLType.SoQLPointT) {
    return castTo(stringLiteral('POINT(0 0)'), st);
  } else if (st === SoQLType.SoQLLineT) {
    return castTo(stringLiteral('LINESTRING (30 10, 10 30, 40 40)'), st);
  } else if (st === SoQLType.SoQLPolygonT) {
    return castTo(stringLiteral('POLYGON ((30 10, 40 40, 20 40, 10 20, 30 10))'), st);
  } else if (st === SoQLType.SoQLMultiPointT) {
    return castTo(stringLiteral('MULTIPOINT ((10 40), (40 30), (20 20), (30 10))'), st);
  } else if (st === SoQLType.SoQLMultiLineT) {
    return castTo(stringLiteral('MULTILINESTRING ((10 10, 20 20, 10 40), (40 40, 30 30, 40 20, 30 10))'), st);
  } else if (st === SoQLType.SoQLMultiPolygonT) {
    return castTo(
      stringLiteral('MULTIPOLYGON (((30 20, 45 40, 10 40, 30 20)), ((15 5, 40 10, 10 20, 5 10, 15 5)))'),
      st
    );
  }
  return typedNullLiteral;
}

export function isFixed(st: SoQLType): boolean {
  return st === SoQLType.SoQLFixedTimestampT || st === SoQLType.SoQLFixedTimestampAltT;
}
export function isFloating(st: SoQLType): boolean {
  return st === SoQLType.SoQLFloatingTimestampT || st === SoQLType.SoQLFloatingTimestampAltT;
}

export function momentToString(m: Moment, st: SoQLType): Option<string> {
  if (isFixed(st)) return some(m.toISOString());
  if (isFloating(st)) return some(m.format('YYYY-MM-DDTHH:mm:ss'));
  return none;
}

export function dateToString(d: Date, st: SoQLType): Option<string> {
  if (d === null) return none;
  if (isFixed(st)) return some(moment(d).toISOString());
  if (isFloating(st)) return some(moment(d).format('YYYY-MM-DDTHH:mm:ss'));
  return none;
}

function stringLiteral(value: string): TypedSoQLStringLiteral {
  return { type: 'string_literal', value, soql_type: SoQLType.SoQLTextT };
}

export function castTo(expr: TypedExpr, to: SoQLType): TypedSoQLFunCall {
  return {
    type: 'funcall',
    function_name: `cast$${usingNBEName(to)}`,
    args: [expr],
    window: null,
    soql_type: to
  };
}

export function getAllExpr(un: UnAnalyzedAst): Expr[] {
  const exprs = un.selection.exprs
    .map((e) => e.expr)
    .concat(
      un.group_bys,
      un.order_bys.map((o) => o.expr),
      un.joins.map((j) => j.on)
    );

  un.joins.map((j) => {
    if (j.from.type == 'sub_select') {
      const joinSubSelectExpr = getAllExpr(lastInChain(j.from.sub_select.selects));
      exprs.concat(joinSubSelectExpr);
    }
  });

  if (distinctIsDistinctOn(un.distinct)) exprs.concat(un.distinct.distinct_on);

  if (un.where) exprs.push(un.where);
  if (un.having) exprs.push(un.having);
  return exprs;
}

export function isAggregateCall(scope: Scope, funcall: FunCall): boolean {
  const call: FunSpec | undefined = scope.find((funspec) => funspec.name === funcall.function_name);
  if (call) {
    return call.is_aggregate;
  }
  return false;
}

// Determines whether an expr contains an aggregate.
export function containsAggregate(scope: Scope, expr: Expr): boolean {
  return traverseExpr(expr, false, (node, acc: boolean) => {
    if (node && isFunCall(node) && isAggregateCall(scope, node)) return true;
    return acc;
  });
}

// Determines whether an expr contains a parameter.
export function containsParameter(exprs: Expr[], param: Parameter): boolean {
  return exprs.some((expr) => {
    return traverseExpr(expr, false, (node, acc: boolean) => {
      if (node && isParameter(node) && node.name === param.name && node.table === param.table) return true;
      return acc;
    });
  });
}

export const hasGroupBys = (ast: UnAnalyzedAst | TypedSelect) => !_.isEmpty(ast.group_bys);

// Determines whether there are any aggregate selections in an unanalyzed ast.
export const hasAggregates = (ast: UnAnalyzedAst | TypedSelect, scope: Scope) => {
  const exprs = ast.selection.exprs.map((e) => e.expr);
  return _.reduce(exprs, (hasAggregate, expr) => hasAggregate || containsAggregate(scope, expr), false);
};

/* Given a column name OR an expr, tries to determine whether it is used in group bys.
 * Not foolproof. TODO: EN-44562 */
export const isUsedInGroupBy = (name: string, expr: Either<Expr, TypedExpr> | null, astEither: Either<UnAnalyzedAst, TypedSelect>) => {
  const columnRefAliases = astEither.fold(
    (ast) => {
      return ast.group_bys.filter(isColumnRef).map((colRef) => colRef.value);
    },
    (ast) => {
      return ast.group_bys.filter(isTypedColumnRef).map((colRef) => colRef.value);
    });
  const exprMatches = (currExpr: Expr | TypedExpr) =>
    astEither.foldEither(ast => {
      return _.find(ast.group_bys, (groupedExpr: Expr | TypedExpr) => isExpressionEqualIgnoringPosition(groupedExpr, currExpr)) !== undefined;
    });

  /* Cannot just check if expressions are equal because, in the example of
   * select 1 as num group by num, group by treats num as a column ref while
   * it is recognized as a literal in expr. */
  return columnRefAliases.includes(name) || (!_.isNull(expr) && expr.foldEither(e => exprMatches(e)));
};

export const isUsedInGroupByOld = (name: string, expr: Expr | null, ast: UnAnalyzedAst) => {
  const columnRefAliases = ast.group_bys.filter(isColumnRef).map((colRef: ColumnRef) => colRef.value);
  const exprMatches = (currExpr: Expr) =>
    _.find(ast.group_bys, (groupedExpr) => isExpressionEqualIgnoringPosition(groupedExpr, currExpr)) !==
    undefined;

  /* Cannot just check if expressions are equal because, in the example of
   * select 1 as num group by num, group by treats num as a column ref while
   * it is recognized as a literal in expr. */
  return columnRefAliases.includes(name) || (!_.isNull(expr) && exprMatches(expr));
};


export function hasGroupOrAggregate(ast: Option<UnAnalyzedAst | TypedSelect>, scope: Scope) {
  return ast
    .map((unanalyzed: UnAnalyzedAst | TypedSelect) => hasAggregates(unanalyzed, scope) || hasGroupBys(unanalyzed))
    .getOrElseValue(false);
}

export function pluckColumnRefs(expr: Expr): ColumnRef[] {
  return traverseExpr(expr, [], (node, acc: ColumnRef[]) => {
    if (node && isColumnRef(node)) return [...acc, node];
    return acc;
  });
}

export function isSubExpr(needle: Expr, haystack: Expr): boolean {
  return traverseExpr(haystack, false, (node, acc: boolean) => {
    if (acc) return acc;
    if (node && isExpressionEqualIgnoringPosition(needle, node)) return true;
    return acc;
  });
}

// given a query like "select lower(primary_breed) as lower_breed, species || '-' || lower_breed as species_breed"
// this can take an expr like "species_breed || '!!'"
// and return "(species || '-' || lower(primary_breed)) || '!!'"
// NOTE: this gets position information all wrong, so if you use it for a purpose that cares about that, please fix
export function expandExplicitlyAliasedColumnRefs(expr: Expr, ast: UnAnalyzedAst): Expr {
  // handles non-function nodes
  const getExpandedNode = (node: Expr): Expr => {
    if (node && isColumnRef(node)) {
      const maybeExpandedExpr = ast.selection.exprs.find((se) => {
        return se.name && se.name.name == node.value;
      })?.expr;
      if (!maybeExpandedExpr || isColumnRef(maybeExpandedExpr)) {
        return node;
      } else {
        return expandExplicitlyAliasedColumnRefs(maybeExpandedExpr, ast);
      }
    } else {
      return node;
    }
  };

  if (expr.type === 'funcall') {
    return {
      ...expr,
      args: expr.args.map((arg) => expandExplicitlyAliasedColumnRefs(arg, ast))
    };
  } else {
    return getExpandedNode(expr);
  }
}

type Traversable = Expr | null;
export function traverseExpr<T>(node: Traversable, acc: T, fun: (n: Traversable, a: T) => T): T {
  if (node && node.type === 'funcall') {
    return fun(
      node,
      node.args.reduce((nodeAcc, subnode) => traverseExpr(subnode, nodeAcc, fun), acc)
    );
  } else if (node && node.type === 'let') {
    const clausesAcc = node.clauses.reduce((nodeAcc, clause) => traverseExpr(clause, nodeAcc, fun), acc);
    const bodyAcc = traverseExpr(node.body, clausesAcc, fun);
    return fun(node, bodyAcc);
  } else {
    return fun(node, acc);
  }
}

// this is like n^2 at least...use carefully. if used in a render loop the react demons will come
export function containsNonAggregatedExpr(
  needle: Expr,
  haystack: Expr,
  scope: Scope,
  inAggregate = false
): boolean {
  return traverseExpr(haystack, false, (node, acc: boolean) => {
    if (node) {
      if (isExpressionEqualIgnoringPosition(node, needle)) return !inAggregate;
      if (isFunCall(node)) {
        inAggregate = inAggregate || isAggregateCall(scope, node);
        return !!node.args.find((arg) => containsNonAggregatedExpr(needle, arg, scope, inAggregate));
      }
    }
    return acc;
  });
}

function normalizeFunTranslationKeyForYamlLocaleApp(k: string): string {
  return k.replace('%', 'modulo').toLowerCase();
}

export const isFunctionDisplayable = (fs: FunSpec) => isFunctionTranslated(fs) && !isExcludedFunction(fs);

const isFunctionTranslated = (fs: FunSpec) =>
  hasTranslation(normalizeFunTranslationKeyForYamlLocaleApp(fs.name), 'shared.explore_grid.functions');

// functions we will not offer on VQE (visual elements) for various reasons
const isExcludedFunction = (fs: FunSpec): boolean => {
  const excludedFuncNames = [
    // AND and OR have a custom UX path for adding clauses
    'op$and',
    'op$or',
    // you can't use any of these without 'over', which is right now more complex than we want to deal with in the visual section
    'first_value',
    'last_value',
    'rank',
    'dense_rank',
    'row_number',
    // over is more complex than we want to optimize for in the visual section.
    '#wf_over'
  ];
  return excludedFuncNames.includes(fs.name.toLowerCase());
};

function translateKey(fun: string): string {
  return fetchTranslation(normalizeFunTranslationKeyForYamlLocaleApp(fun), 'shared.explore_grid.functions');
}
export function translateFunCall(fc: FunCall): string {
  return translateKey(fc.function_name);
}
export function translateFunction(fs: FunSpec): string {
  return translateKey(fs.name);
}
export function translateFunCallByName(functionName: string): string {
  return translateKey(functionName);
}

interface ArgResolution {
  [index: number]: SoQLType[];
}
interface ArgConstraints {
  [name: string]: SoQLType[];
}

export const argSpecAtIndex = (fs: FunSpec, argIndex: number): Option<Type> => {
  let argSpec = fs.sig[argIndex];
  if (!argSpec && !_.isEmpty(fs.variadic)) {
    // the spec is variadic, and we've run out of items in the signature
    // Example:
    // the "case" function has
    //   sig: [fixed(boolean), var(a)]
    //   variadic: [fixed(boolean), var(a)]
    // let's say we're at index 2, we need argSpec to be the predicate
    // (2 - 2) % 2 = 0, we choose the fixed(boolean)
    // at index 3
    // (3 - 2) % 2 = 1, we choose the var(a)
    argSpec = fs.variadic[(argIndex - fs.sig.length) % fs.variadic.length];
  }

  return option(argSpec);
};

export const resolveConstraints = (call: TypedSoQLFunCall, fs: FunSpec): ArgConstraints => {
  return call.args.reduce((acc, callArg, idx) => {
    const maybeArgSpec = argSpecAtIndex(fs, idx);
    // if there's no expression in this position for the spec, we can choose it, but we'll fill
    // the argument is as null
    // if there is no spec for this position in the expr, we'll remove it
    if (!callArg || maybeArgSpec.isEmpty) return acc;
    const argSpec = maybeArgSpec.get;

    // null works for anything, but doesn't add any type constraints
    if (isTypedNullLiteral(callArg)) return acc;

    if (argSpec.kind === 'variable') {
      if (acc[argSpec.type]) {
        // If the constraint has already been defined by a previous arg, we need to
        // use it.
        // Example:
        // op$> has signature [ var(a, [SoQLNumber, SoQLText, ...etc]), var(a, [SoQLNumber, SoQLText, ...etc])]
        // meaning it can take a first arg of type `a` and a second arg of type `a`, which can
        // be SoQLNumber OR SoQLText, but they both need to be the same
        return acc;
      } else {
        // the variable type hasn't been defined yet, so our type is now the variable type
        // if it satisfies the spec's constraints on what type that arg can be
        const bounds: SoQLType[] = fs.constraints[argSpec.type];
        if (!bounds || bounds.includes(callArg.soql_type)) {
          // Example: convex_hull(a) has bounds where `a` must be a geospatial type
          return {
            ...acc,
            [argSpec.type]: [callArg.soql_type]
          };
        } else if (bounds) {
          return {
            ...acc,
            [argSpec.type]: bounds
          };
        }
      }
    }
    return acc;
  }, {} as Record<string, SoQLType[]>);
};

export const resolveTypesAtPositions = (call: TypedSoQLFunCall, fs: FunSpec): ArgResolution => {
  const constraints = resolveConstraints(call, fs);
  return fs.sig.map((argSpec) => {
    if (argSpec.kind === 'fixed') {
      return [argSpec.type];
    } else {
      return constraints[argSpec.type];
    }
  });
};

export const makeFilter = (
  newExpr: Expr,
  ofType: SoQLType,
  existingExpr?: Expr | null,
  combinator?: string
): Expr =>
  existingExpr ? additionalFilter(existingExpr, newExpr, ofType, combinator) : initialFilter(newExpr, ofType);


export const makeFilterNA = (
  newExpr: TypedExpr,
  ofType: SoQLType,
  existingExpr?: TypedExpr | null,
  combinator?: string
): Expr =>
  existingExpr ? additionalFilterNA(existingExpr, newExpr, ofType, combinator) : initialFilterNA(newExpr, ofType);

export const initialFilter = (expr: Expr, ofType: SoQLType | null): Expr => {
  if (ofType !== null && (isFixed(ofType) || isFloating(ofType))) {
    // generally, date filters are filtering a range
    return {
      type: 'funcall',
      function_name: SoQLFunCall.Between,
      args: [expr, emptyExprOfType(ofType), emptyExprOfType(ofType)],
      window: null
    };
  }
  // caseless one of only works on text types
  if (ofType === SoQLType.SoQLTextT) {
    return {
      type: 'funcall',
      function_name: SoQLFunCall.CaselessOneOf,
      args: [expr, nullLiteral],
      window: null
    };
  }

  return {
    type: 'funcall',
    function_name: SoQLFunCall.In,
    args: [expr, nullLiteral],
    window: null
  };
};

export const initialFilterNA = (expr: TypedExpr, ofType: SoQLType | null): TypedExpr => {
  if (ofType !== null && (isFixed(ofType) || isFloating(ofType))) {
    // generally, date filters are filtering a range
    return {
      type: 'funcall',
      function_name: SoQLFunCall.Between,
      args: [expr, emptyExprOfType(ofType), emptyExprOfType(ofType)],
      window: null,
      soql_type: SoQLType.SoQLBooleanT
    };
  }
  // caseless one of only works on text types
  if (ofType === SoQLType.SoQLTextT) {
    return {
      type: 'funcall',
      function_name: SoQLFunCall.CaselessOneOf,
      args: [expr, typedNullLiteral],
      window: null,
      soql_type: SoQLType.SoQLBooleanT
    };
  }

  return {
    type: 'funcall',
    function_name: SoQLFunCall.In,
    args: [expr, typedNullLiteral],
    window: null,
    soql_type: SoQLType.SoQLBooleanT
  };
};

export const additionalFilterNA = (
  existingExpr: TypedExpr,
  newExpr: TypedExpr,
  ofType: SoQLType | null,
  combinator = 'op$AND'
): TypedExpr => ({
  type: 'funcall',
  function_name: combinator,
  args: [existingExpr, initialFilterNA(newExpr, ofType)],
  window: null,
  soql_type: SoQLType.SoQLBooleanT
});

export const additionalFilter = (
  existingExpr: Expr,
  newExpr: Expr,
  ofType: SoQLType | null,
  combinator = 'op$AND'
): Expr => ({
  type: 'funcall',
  function_name: combinator,
  args: [existingExpr, initialFilter(newExpr, ofType)],
  window: null,
});

export const getLiteralSoqlType = (exprType: string): SoQLType => {
  switch (exprType) {
    case 'string_literal':
      return SoQLType.SoQLTextT;
    case 'number_literal':
      return SoQLType.SoQLNumberT;
    case 'boolean_literal':
      return SoQLType.SoQLBooleanT;
    default:
      return SoQLType.SoQLTextT;
  }
};

export const getFunctionSoqlType = (funcall: FunCall, scope: Scope): SoQLType => {
  const functionInfo = scope.find((f) => f.name === funcall.function_name);
  return functionInfo && isFixedFunType(functionInfo.result)
    ? (functionInfo.result.type as SoQLType)
    : SoQLType.SoQLTextT;
};

export const getColumnSoqlType = (column: ColumnRef, projectionInfo: ProjectionInfo): SoQLType => {
  const columnInfo = projectionInfo
    .map((info) => info.analyzed.find((col) => col.name === column.value))
    .getOrElseValue(undefined);
  return columnInfo ? (columnInfo.expr.soql_type as SoQLType) : SoQLType.SoQLTextT;
};

export const toTyped = (expr: Expr, scope: Scope, projectionInfo: ProjectionInfo): TypedExpr => {
  if (isFunCall(expr)) {
    const soql_type = getFunctionSoqlType(expr, scope);
    const args = expr.args.map((arg) => toTyped(arg, scope, projectionInfo));
    const window = expr.window
      ? {
          partitions: expr.window.partitions.map((partition) => toTyped(partition, scope, projectionInfo)),
          orderings: expr.window.orderings.map((ordering) => ({
            ...ordering,
            expr: toTyped(ordering.expr, scope, projectionInfo)
          })),
          frames: expr.window.frames.map((frame) => toTyped(frame, scope, projectionInfo))
        }
      : null;
    return { ...expr, args, soql_type, window } as TypedExpr;
  } else if (isColumnRef(expr)) {
    return { ...expr, soql_type: getColumnSoqlType(expr, projectionInfo) } as TypedExpr;
  } else {
    // If Expr isn't funcall or column ref, it's a literal
    return { ...expr, soql_type: getLiteralSoqlType(expr.type) } as TypedExpr;
  }
};

// Given a TypedExpr's function_name, finds the equivalent Expr's function_name.
const toExprFunctionName = (typedExprFunctionName: string): string => {
  switch (typedExprFunctionName) {
    case TypedFunCall.CountStar:
      return SoQLFunCall.CountStar;
    case TypedFunCall.Median:
      return SoQLFunCall.Median;
    default:
      return typedExprFunctionName;
  }
};

// Transforms a TypedExpr to an Expr.
export const toExpr = (typedExpr: TypedExpr): Expr => {
  if (isTypedFunCall(typedExpr)) {
    const { soql_type, ...rest } = typedExpr;
    const args = typedExpr.args.map((arg) => toExpr(arg));
    const function_name = toExprFunctionName(typedExpr.function_name);
    const window = typedExpr.window
      ? {
          partitions: typedExpr.window.partitions.map((partition) => toExpr(partition)),
          orderings: typedExpr.window.orderings.map((ordering) => ({
            ...ordering,
            expr: toExpr(ordering.expr)
          })),
          frames: typedExpr.window.frames.map((frame) => toExpr(frame))
        }
      : null;
    return { ...rest, args, function_name, window } as Expr;
  } else {
    const { soql_type, ...rest } = typedExpr;
    return rest as Expr;
  }
};

/* Explicitly alias aggregate selections iff the aggregate selection doesn't have an explicit alias.
 * use = UnAnalyzedSelectedExpression, ase = AnalyzedSelectedExpression */
export const explicitlyAliasAggregates = (scope: Scope, compiled: QueryCompilationSucceeded) => {
  const uses = lastInChain(compiled.unanalyzed).selection.exprs;
  const used = uses.filter((use) => use.name).map((use) => use.name && use.name.name);
  // Remove the in-use explicit aliases aggregates from ases.
  let ases = lastInChain(compiled.analyzed).selection.filter((ase) => !used.includes(ase.name));
  uses.forEach((use) => {
    if (containsAggregate(scope, use.expr) && use.name === null) {
      const foundAse = _.find(ases, (ase) => {
        const expr = toExpr(ase.expr);
        return isExpressionEqualIgnoringPosition(expr, use.expr);
      });
      if (foundAse) {
        // Promote implicit alias (from ase) to explicit alias (put in use) and remove from ases because it's been used.
        ases = _.without(ases, foundAse);
        use.name = { position: NoPosition, name: foundAse.name };
      }
    }
  });
};

export const qualifiedNameFromColumnRef = (ref: ColumnRef): string =>
  ref.qualifier === null ? ref.value : `${ref.qualifier.slice(1)}_${ref.value.replace(/^[:@]{2}/, '')}`;

interface UsedFieldNames {
  explicitAliases: string[];
  refs: string[];
}
/* Get all field names used in selection (explicit aliases or dataset column names) across compiled or
 * last run ast. These lists do not include any implicit aliases. */
export const existingFieldNames = (
  ast: UnAnalyzedAst,
  projectionInfo: ProjectionInfo,
  scope: Scope
): string[] => {
  /* If there is already an existing group or aggregate, find all existing field names from the selections,
   * and partition them by either a dataset column name (ref) or an explicit alias (alias takes precedence
   * over dataset column name). */
  const extractFieldNames = (result: UsedFieldNames, use: UnAnalyzedSelectedExpression) => {
    const { name, expr } = use;
    const ref = isColumnRef(expr) ? [expr.value] : [];
    const alias = name ? [name.name] : [];
    return _.isEmpty(alias)
      ? { ...result, refs: result.refs.concat(ref) }
      : { ...result, explicitAliases: result.explicitAliases.concat(alias) };
  };

  const lastRunExplictAliases = projectionInfo
    .map((pi) => pi.unanalyzed.exprs.flatMap((use) => (use.name ? [use.name.name] : [])))
    .getOrElseValue([]);
  const { explicitAliases, refs } = _.reduce(ast.selection.exprs, extractFieldNames, {
    explicitAliases: lastRunExplictAliases,
    refs: []
  });

  /* If there are no groups and no aggregates, the new aggregate's api field name does not need to be
   * unique within all selections, just unique among any explicit aliases. */
  return hasGroupOrAggregate(option(ast), scope)
    ? _.uniq(explicitAliases.concat(refs))
    : _.uniq(explicitAliases);
};

export function isExprStillValid(
  selectionsToDrop: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[],
  expr: Expr
) {
  const references: string[] = pluckColumnRefs(expr).map((ref) => ref.value);
  if (
    _.some(selectionsToDrop, (dropped) => _.some(references, (name) => _.isEqual(name, dropped.typed.name)))
  ) {
    return false;
  }
  return true;
}
export function exprDoesNotReferenceSelections(selections: SelectionItem[] | NamedExpr[], expr: Expr) {
  // This should really be implemented as a set intersection, but that functionality isn't part of the JS stdlib yet.
  const references = pluckColumnRefs(expr).map((ref) => ref.value);
  const names = selections.map((s) => ('schemaEntry' in s) ? s.schemaEntry.name : s.name?.name);

  return option(names.find((name) => references.find((ref) => _.isEqual(name, ref)))).isEmpty;
}

export function expandingFunctionInCalcColumns(
  selectionToDrop: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>,
  exprToExpand: Expr | null
): Expr {
  if (exprToExpand && isExprStillValid([selectionToDrop], exprToExpand)) {
    return exprToExpand;
  }

  return JSON.parse(
    JSON.stringify(exprToExpand, (key, value) => {
      if (
        value !== null &&
        isColumnRef(value) &&
        value.value == selectionToDrop.typed.name &&
        (!isColumnRef(selectionToDrop.typed.expr) || value.qualifier == selectionToDrop.typed.expr.qualifier)
      ) {
        return selectionToDrop.untyped.expr;
      } else return value;
    })
  );
}
export function expandAliasesToCalculation(
  selectionToDrop: SelectionItem,
  exprToExpand: TypedExpr | null
): TypedExpr {
  if (exprToExpand && exprDoesNotReferenceSelections([selectionToDrop], exprToExpand)) {
    return exprToExpand;
  }

  return JSON.parse(
    JSON.stringify(exprToExpand, (key, value) => {
      if (
        value !== null &&
        isColumnRef(value) &&
        value.value === selectionToDrop.schemaEntry.name &&
        (!isColumnRef(selectionToDrop.expr) || value.qualifier === selectionToDrop.expr.qualifier)
      ) {
        return selectionToDrop.expr;
      } else {
        return value;
      }
    })
  );
}

const updateReferenceInExpression = (expr: Expr, oldRef: ColumnRef, newRef: ColumnRef): Expr =>
  mapExpr(expr, (n: Expr) => {
    if (isColumnRef(n) && isColumnEqualIgnoringPosition(n, oldRef)) {
      return newRef;
    }
    return n;
  });

const updateReferenceInSelection = (
  selection: UnAnalyzedSelection,
  oldRef: ColumnRef,
  newRef: ColumnRef
): UnAnalyzedSelection => ({
  ...selection,
  exprs: selection.exprs.map((se) => ({
    ...se,
    // say we have the query:
    //  SELECT foo as bar, bar + 1 as baz
    // and the user is renaming the field name "bar" to "qux"
    // we need to change:
    // bar + 1 -> qux + 1
    expr: updateReferenceInExpression(se.expr, oldRef, newRef)
  }))
});

const updateReferenceInOrderBy = (ob: OrderBy, oldRef: ColumnRef, newRef: ColumnRef): OrderBy => ({
  ...ob,
  expr: updateReferenceInExpression(ob.expr, oldRef, newRef)
});

const updateReferenceInJoin = (
  join: UnAnalyzedJoin,
  oldRef: ColumnRef,
  newRef: ColumnRef
): UnAnalyzedJoin => ({
  ...join,
  on: updateReferenceInExpression(join.on, oldRef, newRef)
});

export function updateReference(ast: UnAnalyzedAst, oldRef: ColumnRef, newRef: ColumnRef): UnAnalyzedAst {
  return {
    ...ast,
    selection: updateReferenceInSelection(ast.selection, oldRef, newRef),
    where: ast.where ? updateReferenceInExpression(ast.where, oldRef, newRef) : ast.where,
    having: ast.having ? updateReferenceInExpression(ast.having, oldRef, newRef) : ast.having,
    group_bys: ast.group_bys.map((expr) => updateReferenceInExpression(expr, oldRef, newRef)),
    order_bys: ast.order_bys.map((ob) => updateReferenceInOrderBy(ob, oldRef, newRef)),
    joins: ast.joins.map((j) => updateReferenceInJoin(j, oldRef, newRef))
  };
}

export function updateExpressionName(
  ast: UnAnalyzedAst,
  expressionToName: UnAnalyzedSelectedExpression,
  name: string
): UnAnalyzedAst {
  return {
    ...ast,
    selection: {
      ...ast.selection,
      exprs: ast.selection.exprs.map((selectedExpr) => ({
        ...selectedExpr,
        // There can be duplicate expr.values so check the name as well
        name:
          selectedExpr.name?.name === expressionToName.name?.name &&
          _.isEqual(selectedExpr.name?.position, expressionToName.name?.position) &&
          isExpressionEqualIgnoringPosition(selectedExpr.expr, expressionToName.expr)
            ? {
                name,
                position: { line: 0, column: 0 }
              }
            : selectedExpr.name
      }))
    }
  };
}

export const hasUnsaveableComponents = (cr: Option<QueryCompilationResult>) => {
  return compilationSuccess(cr)
    .map((compSuccess) => {
      const ast = lastInChain(compSuccess.unanalyzed);
      return (
        ast.selection.all_user_except.length > 0 ||
        ast.selection.all_system_except !== null ||
        ast.offset !== null
      );
    })
    .getOrElseValue(false);
};

export function isUnEditable<E>(eexpr: EexprNA<E>): eexpr is UnEditableExpressionNA<E>;
export function isUnEditable<U, T>(eexpr: Eexpr<U, T>): eexpr is UnEditableExpression<U>;
export function isUnEditable<E, U, T>(eexpr: Eexpr<U, T> | EexprNA<E>) {
  return 'error' in eexpr;
}
