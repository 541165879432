import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import { createDefaultHierarchy } from 'common/authoring_workflow/helpers';

const defaultHierarchy = createDefaultHierarchy();

export default {
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  configuration: {
    viewSourceDataLink: true,
    showDataTableControl: false
  },
  description: '',
  series: [
    {
      color: {},
      formatting: {
        columnFormat: {},
        headerFormat: {}
      },
      dataSource: {
        activeHierarchyId: defaultHierarchy.id,
        datasetUid: null,
        dimension: {
          columnName: null,
          aggregationFunction: null,
          columns: []
        },
        hierarchies: [defaultHierarchy],
        measure: {
          columnName: null,
          aggregationFunction: 'count'
        },
        type: 'socrata.soql',
        filters: []
      },
      label: null,
      type: 'agTable',
      unit: {
        one: 'row',
        other: 'rows'
      }
    }
  ],
  title: ''
};
