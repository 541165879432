import * as _ from 'lodash';
import Flyout from 'common/components/Flyout';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { Either, factorOption,  } from 'common/either';
import { fetchTranslation } from 'common/locale';
import {
  extractPositionFromError,
  QueryAnalysisFailed,
  AnalysisFailedDetails,
  QueryCompilationFailed,
  CompilationFailedDetails,
  getFunctionName,
  getSoQLType,
  TypeMismatch
} from 'common/types/compiler';
import { Expr, TypedExpr, SoQLType, isFunCall } from 'common/types/soql';
import React from 'react';
import { none, Option, some } from 'ts-option';
import FunctionBadge from './FunctionBadge';
import SoQLTypeIcon from 'common/components/SoQLTypeIcon';
import { isUnEditable } from 'common/explore_grid/lib/soql-helpers';
import { whichAnalyzer } from 'common/explore_grid/lib/feature-flag-helpers';
import { Eexpr, EexprNA } from 'common/explore_grid/types';

type SoQLErrorDetails = CompilationFailedDetails | AnalysisFailedDetails;

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.compiler_errors');
const translateType = (type: SoQLType) => fetchTranslation(type, 'shared.explore_grid.type_display_names');
const translateTitle = (d: SoQLErrorDetails) => t(d.type.replace(new RegExp('-', 'g'), '_'));

const TypeBadge: React.FC<{ type: SoQLType }> = ({ type }) => (
  <div className="badge">
    <SoQLTypeIcon type={type} />
    {translateType(type)}
  </div>
);

const CompilerErrorFlyout: React.FC<{ title: string }> = (props) => {
  return (
    <div className="compiler-error">
      <div>
        <p className="error-type text-quiet">{props.title}</p>
        <div className="error-details">{props.children}</div>
      </div>
    </div>
  );
};

interface ErrorDetailsProps<T extends SoQLErrorDetails> {
  details: T;
}

const TypeError = ({ details }: ErrorDetailsProps<TypeMismatch>) => {
  const funName = getFunctionName(details.name);
  return (
    <CompilerErrorFlyout title={translateTitle(details)}>
      <div>
        <FunctionBadge fun={funName} />
        {t('does_not_accept_type')}
        <TypeBadge type={getSoQLType(details.actual)} />
        {t('at_this_position')}
      </div>
    </CompilerErrorFlyout>
  );
};

const ErrorDetails = ({ details }: ErrorDetailsProps<SoQLErrorDetails>) => {
  // The idea here is that as we want to add more useful/custom/fancy error messages for
  // specific customer use cases, we can add special components that render the errors in a
  // nice way. It falls back to something reasonable, but we have an easy place to extend this
  // rendering logic to make it nice for certain situations
  if (details.type === 'type-mismatch') return <TypeError details={details} />;

  return (
    <CompilerErrorFlyout title={translateTitle(details)}>
      <p>{details.english}</p>
    </CompilerErrorFlyout>
  );
};

interface Props {
  error: Either<QueryCompilationFailed, QueryAnalysisFailed>;
}

const matchesPosition = (expr: Expr | null, error: QueryCompilationFailed | QueryAnalysisFailed) => {
  const exprPosn = expr && expr.position;
  const errorPosn = whichAnalyzer(
    () => error as QueryCompilationFailed,
    () => error as QueryAnalysisFailed
  )().fold(e => e.soql_exception.position, e => extractPositionFromError(e.soql_exception));
  return errorPosn?.row === exprPosn?.line && errorPosn?.column === exprPosn?.column;
};
const appliesToMoreSpecificSubExpression = (expr: Expr | null, error: QueryCompilationFailed | QueryAnalysisFailed) => {
  if (expr && isFunCall(expr)) {
    return _.some(expr.args, (arg) => matchesPosition(arg, error));
  }
  return false;
};

export const getCompilerError = (
  eexprEither: Either<Eexpr<Expr | null, TypedExpr | null>, EexprNA<TypedExpr | null>>
): Option<Either<QueryCompilationFailed, QueryAnalysisFailed>> => {
  return factorOption(eexprEither.mapBoth(
    eexpr => {
      // get the compiler error for this node. If the compiler error is present but for a
      // different node in the AST, the result is none
      if (isUnEditable(eexpr)) {
        if (
          matchesPosition(eexpr.untyped, eexpr.error) &&
          // only render the error on the innermost subexpression, as position information for a subexpr
          // can match that of its parent.
          !appliesToMoreSpecificSubExpression(eexpr.untyped, eexpr.error)
        ) {
          return some(eexpr.error);
        }
      }
      return none;
    },
    eexpr => {
      // get the compiler error for this node. If the compiler error is present but for a
      // different node in the AST, the result is none
      if (isUnEditable(eexpr)) {
        if (
          matchesPosition(eexpr.expr, eexpr.error) &&
          // only render the error on the innermost subexpression, as position information for a subexpr
          // can match that of its parent.
          !appliesToMoreSpecificSubExpression(eexpr.expr, eexpr.error)
        ) {
          return some(eexpr.error);
        }
      }
      return none;
    }
  ));
};

const CompilerError: React.FC<Props> = (props) => {
  const details: SoQLErrorDetails = props.error.fold(
    e => e.soql_exception as SoQLErrorDetails,
    e => e.soql_exception as SoQLErrorDetails
  );
  return (<div className="compilation-failure">
    <Flyout text={<ErrorDetails details={details} />} right>
      <SocrataIcon name={IconName.Warning} />
    </Flyout>
  </div>
  );
};

export default CompilerError;
