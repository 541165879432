import { connect } from 'react-redux';
import { fetchTranslation } from 'common/locale';
import {
  ColumnRef,
  Expr,
  isColumnRef,
  Scope,
  SoQLType,
  TypedExpr,
  TypedSelect,
  UnAnalyzedAst
} from 'common/types/soql';
import { AppState } from '../redux/store';
import { whichAnalyzer } from '../lib/feature-flag-helpers';
import { getLastUnAnalyzedAst, getRightmostLeafFromAnalysis, ProjectionInfo, ProjectionInfoNA, ViewColumnColumnRef } from '../lib/selectors';
import { toDatasetColumn, toQueryColumn, PickableColumn, ProjectionExpr, Selected } from '../lib/column-picker-helpers';
import { containsAggregate, isCalculatedColumn, isUsedInGroupBy, selectionWithProvenance, zipSelection } from '../lib/soql-helpers';
import _ from 'lodash';
import React from 'react';
import { none, Option, some } from 'ts-option';
import { View } from 'common/types/view';
import Picker, { Pickable, PickerSelectedValueFormatting } from './Picker';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';
import { Either, factorOption, left as buildLeft, right as buildRight } from 'common/either';
import { OutputColumn } from 'common/types/compiler';
import { ViewColumn } from 'common/types/viewColumn';
import { FeatureFlags } from 'common/feature_flags';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.column_picker');
const translationWithOption = (k: string, option: any) =>
  fetchTranslation(k, 'shared.explore_grid.column_picker', undefined, option);


const getOptionalColumnSubtitle = (vccr: ViewColumnColumnRef) => vccr.ref.qualifier ? translationWithOption('from_column', { columnName: vccr.view.name }) : undefined;

const formatSelectedDatasetColumn = (vccr: ViewColumnColumnRef): PickerSelectedValueFormatting => (
  {
    label: `${vccr.column.name} ${getOptionalColumnSubtitle(vccr) || ''}`,
    leadingIconName: getForgeIconNameForDataType(_.get(vccr, 'typedRef.soql_type'))
  });

const formatSelectedQueryColumn = (pexpr: ProjectionExpr): PickerSelectedValueFormatting => (
  {
    label: pexpr.name,
    leadingIconName: getForgeIconNameForDataType(_.get(pexpr, 'typedExpr.soql_type'))
  });

const isViewColumnColumnRef = (x: any): x is ViewColumnColumnRef => (
  !_.isUndefined(x.ref) && !_.isUndefined(x.view) && !_.isUndefined(x.column) && !_.isUndefined(x.typedRef)
);

/* If there is a name attached to the unanalyzed selected expr, the expr is
 * aliased and we should create a column ref so it can referenced instead of
 * its underlying expr. */
const toColumnRef = (name: string | undefined, expr: Expr | TypedExpr) => {
  if (!name) return none;
  return some({
    type: 'column_ref',
    value: name,
    qualifier: (isColumnRef(expr)) ? expr.qualifier : null
  } as ColumnRef);
};

interface StateProps {
  ast: Either<Option<UnAnalyzedAst>, Option<TypedSelect>>;
  scope: Scope;
  view: View;
}

export interface Props {
  className?: string;
  /* Available dataset columns. */
  columns: ViewColumnColumnRef[];
  /* Show subsets of pickable columns. Undefined means to show
   * all available columns. See interface ColumnSubset for more
   * information. */
  columnSubset?: ColumnSubset;
  /* Restrict pickable columns shown in dropdown by soql type.
   * Only pickable columns that adhere to soql types in the
   * array will be shown to users. Undefined or empty means
   * that there are no restrictions. */
  soqlTypeConstraints?: SoQLType[];
  /* Information on the current state of unanalyzed and analyzed
   * exprs. Used to find query columns (aka calculated columns).
   * None means that query columns will not be show in the dropdown. */
  projectionInfo: Either<ProjectionInfo, ProjectionInfoNA>;
  /* Show column picker with this prompt when nothing is selected. */
  prompt: string;
  /* Show column picker with this object when a selection has been made. */
  selected: Option<Selected>;
  onSelect: (picked: PickableColumn) => void;
  /* Will cause the picker autocomplete to be open. This is to allow for testing
  * since currently testing an async autocomplete is pretty impossible, maybe with forge 3
  * so I know this sucks but I would rather be able to test this component than not */
  autoCompleteOpen?: boolean;
}

type ColumnPickerProps = StateProps & Props;

interface State {
  showPicker: boolean;
  filter: Option<string>;
}

/**
 * Filter tab:
 * When there is a group or aggregate column:
 *   AggregatedOrGrouped: Show "Others Column": all columns that are not grouped and not aggregated.
 *   Aggregated or Grouped Columns": only columns that are grouped or aggregated.
 *   When there is not a grouped or aggregated column, show all columns.
 * Group tab:
 *   Aggregate by: show all columns except for aggregated columns.
 *   Group by: show all columns except for aggregated columns. */
export enum ColumnSubset {
  AggregatedOrGrouped = 'aggregated-or-grouped',
  NotAggregatedOrGrouped = 'not-aggregated-nor-grouped'
}

/* There are two types of columns that we can select from ColumnPicker.
 * Dataset columns: These are columns that exist on the data source you're querying. They
 *                  originate from the dataset and are shown by their display names in the
 *                  dropdown. Represented by ViewColumnColumnRef.
 * Query columns:   These are calculated columns, which are columns that were created by the query
 *                  or soql. They are shown by their aliased or autogenerated names in the dropdown.
 *                  Represented by ProjectionExpr.
 * When an unaliased query column is selected, it should be expressed by its underlying expression.
 * When an aliased query column is selected, it should be expressed by its alias in the form of a
 * column ref. See EN-44732. */
class ColumnPicker extends React.Component<ColumnPickerProps, State> {
  render() {
    const { className, columns, columnSubset, soqlTypeConstraints, projectionInfo, prompt, scope, ast, onSelect, autoCompleteOpen } = this.props;

    const aliases: string[] = projectionInfo.fold(
      (pi: ProjectionInfo) => (
        pi.map(({ analyzed }) => (
          analyzed.map((e) => e.name)
        ))
      ),
      (pi: ProjectionInfoNA) => (
        pi.map(({ outputSchema }) => (
          outputSchema.map((oc: OutputColumn) => oc.name)
        ))
      )
    ).getOrElseValue([]);

    // Show all columns except for aggregated or grouped columns.
    const showNotAggregatedOrGrouped = columnSubset === ColumnSubset.NotAggregatedOrGrouped;
    // Show only aggregated or grouped columns.
    const showAggregatedOrGrouped = columnSubset === ColumnSubset.AggregatedOrGrouped;

    const isComputedRegionColumn = (column: ViewColumn) => (
      column.fieldName?.startsWith(':@')
    );

    const shouldHideComputedColumns = FeatureFlags.value('hide_computed_region_columns_in_ec');

    const columnOptions: Pickable[] = columns
      .filter(({ column }) => {
        // Default to showing all columns, regardless of grouping, for column pickers used outside
        // of the VisualFilterEditor
        let shouldShowColumn = true;
        const inGroupBy = factorOption(ast).map(tree => isUsedInGroupBy(column.fieldName, null, tree)).getOrElseValue(false);
        const inSelection = aliases.includes(column.fieldName);
        if (showAggregatedOrGrouped) {
          // If we're adding a HAVING filter, we only want to show aggregated or grouped columns.
          // Aggregated columns are always an expr option / calculated column.
          shouldShowColumn = inSelection || inGroupBy;
        } else if (showNotAggregatedOrGrouped) {
          // If we're adding a WHERE filter, we only want to show not aggregated or grouped columns.
          shouldShowColumn = !inSelection && !inGroupBy;
        }
        // hide computed region columns
        if (shouldHideComputedColumns && isComputedRegionColumn(column)) {
          shouldShowColumn = false;
        }

        return shouldShowColumn;
      })
      .map(column => {
        return {
          name: column.column.name!,
          dataType: column.typedRef.soql_type,
          subTitle: getOptionalColumnSubtitle(column),
          group: t('dataset_columns'),
          onSelect: () => onSelect(toDatasetColumn(column))
        };
      });

    const exprOptions: Pickable[] = projectionInfo.fold(
      (pi: ProjectionInfo) => (
        pi.map(({ tableAliases, viewContext, unanalyzed, analyzed }) => {
          return _.zip(zipSelection(viewContext, tableAliases, unanalyzed, analyzed).exprs, analyzed)
            .filter(
              ([expr, an]) => {
                if (!expr || !an) return false; // appease TSC
                // Only non-column refs, since columns are covered by the other group.
                const notColumnRef = !isColumnRef(expr.expr);
                // Checks unanalyzed names against analyzed names.
                const notInProjection = !aliases.includes(an.name);
                // Checks if expr contains an aggregate.
                const hasAggregate = containsAggregate(scope, expr.expr);
                // Checks if name or expr is used in a group.
                const inGroupBy = ast.left
                  .map(un => isUsedInGroupBy(an.name, buildLeft(expr.expr), buildLeft(un)))
                  .getOrElseValue(false);

                return an && expr && notColumnRef &&
                  (
                    notInProjection ||
                    // Show only aggregated or grouped columns.
                    (showAggregatedOrGrouped && (hasAggregate || inGroupBy)) ||
                    // Show all columns except for aggregated or grouped columns.
                    (showNotAggregatedOrGrouped && !hasAggregate && !inGroupBy) ||
                    /* Default: Show all columns except for aggregated columns.
                    * Filter tab should show all columns normally, but when there's a group
                    * or aggregate, it'll split into two subtabs and use one of the two conditions
                    * above, so the default case will never trigger when there's an aggregate. */
                    (!showAggregatedOrGrouped && !showNotAggregatedOrGrouped && !hasAggregate)
                  );
              }
            )
            .flatMap(([expr, an]) => {
              if (!expr || !an) return []; // appease TSC

              const pexpr = {
                expr: expr.expr,
                typedExpr: an.expr,
                name: an.name,
                ref: toColumnRef(expr.name?.name, expr.expr)
              } as ProjectionExpr;

              return [
                {
                  name: an.name,
                  dataType: an.expr.soql_type,
                  group: t('query_columns'),
                  onSelect: () => onSelect(toQueryColumn(pexpr))
                }
              ];
            });
        })
      ),
      (pi: ProjectionInfoNA) => (
        // take all selections
        pi.map(({ selection: astSelection, outputSchema, tableAliases, viewContext }) => {
          const selectionItems =  selectionWithProvenance(astSelection, outputSchema, tableAliases, viewContext);
          const filteredSelectionItems = selectionItems.filter((selectionItem) => {
            // return only things that are:
            // 1. not column refs
            const notColumnRef = !isColumnRef(selectionItem.expr);
            const hasAggregate = containsAggregate(scope, selectionItem.expr);
            // Checks if name or expr is used in a group.
            const inGroupBy = factorOption(ast).map((astEither) => (
              isUsedInGroupBy(selectionItem.schemaEntry.name, buildRight(selectionItem.expr), astEither)
            )).getOrElseValue(false);
            return notColumnRef &&
              (
                // Show only aggregated or grouped columns.
                (showAggregatedOrGrouped && (hasAggregate || inGroupBy)) ||
                // Show all columns except for aggregated or grouped columns.
                (showNotAggregatedOrGrouped && !hasAggregate && !inGroupBy) ||
                /* Default: Show all columns except for aggregated columns.
                * Filter tab should show all columns normally, but when there's a group
                * or aggregate, it'll split into two subtabs and use one of the two conditions
                * above, so the default case will never trigger when there's an aggregate. */
                (!showAggregatedOrGrouped && !showNotAggregatedOrGrouped && !hasAggregate)
              );
          });
          return filteredSelectionItems.map((fsi) => {
            // FIXME: This is a hack to make it work like the old analyzer did.
            // We shouldn't need this in the new analyzer world.
            const ref = (() => {
              if (isCalculatedColumn(fsi) && !fsi.hasAlias) {
                return none;
              } else {
                return toColumnRef(fsi.schemaEntry.name, fsi.expr);
              }
            })();

            const pexpr = {
              expr: fsi.expr,
              typedExpr: fsi.expr,
              name: fsi.schemaEntry.name,
              ref
            } as ProjectionExpr;

            return {
              name: fsi.schemaEntry.name,
              dataType: fsi.expr.soql_type,
              group: t('query_columns'),
              onSelect: () => onSelect(toQueryColumn(pexpr))
            };
          });
        })
      )).getOrElseValue([]);

    const formatSelected = this.props.selected
      .map(se => isViewColumnColumnRef(se) ?
        formatSelectedDatasetColumn(se) :
        formatSelectedQueryColumn(se)
      );

    const options = exprOptions.concat(columnOptions);

    return (
      <Picker
        className={className}
        soqlTypeConstraints={soqlTypeConstraints}
        prompt={prompt}
        pickables={options}
        formatSelected={formatSelected}
        dataTestId='column-picker'
        autoCompleteOpen={autoCompleteOpen} // for testing
      />
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ast: whichAnalyzer(() => getLastUnAnalyzedAst(state.query), () => getRightmostLeafFromAnalysis(state.query))(),
    scope: state.scope.getOrElseValue([]),
    view: state.view
  } as StateProps;
};

export default connect(mapStateToProps)(ColumnPicker);
