import { AnalyzedSelectedExpression, ColumnRef, Expr, TypedExpr, TypedSoQLColumnRef } from 'common/types/soql';
import * as _ from 'lodash';
import { Option } from 'ts-option';
import { ViewColumnColumnRef } from './selectors';

/**
 * Representation of a query column/calculated column.
 * @param expr      The untyped underlying expression.
 * @param typedExpr The typed underyling expression.
 * @param name      The current name of the query column. May be the alias or autogenerated name.
 * @param ref       If not aliased, this is none. If aliased, this is the query column expressed a column ref.
 * Not all calculated columns are aliased. Aliased means that there is a explicit annotation of the expression
 * in the form of {expr} as {alias} (e.g. select 1 as one). Not to be confused with the autogenerated names for
 * that happen for all unaliased calculated columns (e.g. select 1 has an automatic name of _1). */
export interface ProjectionExpr {
  expr: Expr;
  typedExpr: TypedExpr;
  name: string; // never null, from AnalyzedSelectedExpression
  ref: Option<ColumnRef | TypedSoQLColumnRef>;
}

/**
 * Two types of columns used in ColumnPicker.
 * Query   Columns that are created using the SoQLEditor that do not _actually_ exist on the dataset.
 * Dataset Columns that are originally on the dataset. */
export enum ColumnType {
  Query = 'query_column',
  Dataset = 'dataset_column'
}

export interface DatasetColumn {
  type: ColumnType.Dataset;
  column: ViewColumnColumnRef;
}

export interface QueryColumn {
  type: ColumnType.Query;
  column: ProjectionExpr
}

// The pickable object in the dropdown of the ColumnPicker.
export type PickableColumn = DatasetColumn | QueryColumn;

// The object to represent a selected option in ColumnPicker.
export type Selected = ViewColumnColumnRef | ProjectionExpr;

export const toDatasetColumn = (vccr: ViewColumnColumnRef) => ({
  type: ColumnType.Dataset,
  column: vccr
} as DatasetColumn);

export const toQueryColumn = (pexpr: ProjectionExpr) => ({
  type: ColumnType.Query,
  column: pexpr
} as QueryColumn);

export const isQueryColumn = (x: PickableColumn): x is QueryColumn => (
  !_.isUndefined(x.type) && x.type === ColumnType.Query
);

export const isDatasetColumn = (x: PickableColumn): x is DatasetColumn => (
  !_.isUndefined(x.type) && x.type === ColumnType.Dataset
);

export const isPickableColumn = (x: any): x is PickableColumn => (
  isQueryColumn(x) || isDatasetColumn(x)
);

export function matchPicked<L, R>(
  picked: PickableColumn,
  datasetColumn: (vccr: ViewColumnColumnRef) => L,
  queryColumn: (pexpr: ProjectionExpr) => R
) {
  if (isDatasetColumn(picked)) {
    return datasetColumn(picked.column);
  } else {
    return queryColumn(picked.column);
  }
}
