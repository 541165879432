import { Both, Either, factorOption, iif, buildBoth } from 'common/either';
import { FeatureFlags } from 'common/feature_flags';
import { FunCall, TypedSoQLFunCall, Expr, TypedExpr } from 'common/types/soql';
import { Eexpr, EexprNA } from '../types';
import { none, Option, option, some } from 'ts-option';
import { Query } from '../redux/store';
import { analysisFailure, analysisSuccess, compilationFailure, compilationSuccess } from '../lib/selectors';
import {
  QueryAnalysisResult, QueryAnalysisFailed, QueryAnalysisSucceeded,
  QueryCompilationSucceeded, QueryCompilationFailed, QueryCompilationResult
} from 'common/types/compiler';

export const usingSoda3EC = () =>
    FeatureFlags.valueOrDefault('soda3_ec', false);

export const whichAnalyzer = iif(usingSoda3EC);
export const eitherForTest = buildBoth(usingSoda3EC);

interface TransitionResultIntoAction<A> {
  compilationAndAnalysisSucceeded: (c: QueryCompilationSucceeded, a: QueryAnalysisSucceeded) => A;
  compilationSucceededButAnalysisFailed: (c: QueryCompilationSucceeded, a: QueryAnalysisFailed) => A;
  compilationFailedButAnalysisSucceeded: (c: QueryCompilationFailed, a: QueryAnalysisSucceeded) => A;
  compilationAndAnalysisFailed: (c: QueryCompilationFailed, a: QueryAnalysisFailed) => A;
  compilationSucceeded: (c: QueryCompilationSucceeded) => A;
  compilationFailed: (c: QueryCompilationFailed) => A;
}
export class TransitionResultType<A> extends Both<QueryCompilationResult, Option<QueryAnalysisResult>> {
  actionV: A;
  status: 'success' | 'failure' | undefined;
  successV: TransitionSuccessType | undefined;
  compilationResult: QueryCompilationResult;
  analysisResult: QueryAnalysisResult | undefined;

  constructor(
    private readonly leftVV: QueryCompilationResult,
    private readonly rightVV: Option<QueryAnalysisResult>,
    private readonly conditionC: () => boolean,
    private readonly actionBuilder: TransitionResultIntoAction<A>
  ) {
    super(leftVV, rightVV, conditionC);

    compilationSuccess(option(this.left)).map(compilationSucceeded => {
      this.compilationResult = compilationSucceeded;
      if (this.isRight) {
        analysisSuccess(this.right).map(analysisSucceeded => {
          this.actionV = this.actionBuilder.compilationAndAnalysisSucceeded(compilationSucceeded, analysisSucceeded);
          this.successV = buildSucceeded(compilationSucceeded, analysisSucceeded);
          this.status = 'success';
          this.analysisResult = analysisSucceeded;
        }).orElse(() => analysisFailure(this.right).map(analysisFailed => {
          this.actionV = this.actionBuilder.compilationSucceededButAnalysisFailed(compilationSucceeded, analysisFailed);
          this.status = 'failure';
          this.analysisResult = analysisFailed;
        }));
      } else {
        this.actionV = this.actionBuilder.compilationSucceeded(compilationSucceeded);
        this.successV = buildSucceeded(compilationSucceeded);
        this.status = 'success';
      }
    }).orElse(() => compilationFailure(option(this.left)).map(compilationFailed => {
      this.compilationResult = compilationFailed;
      if (this.isRight) {
        analysisSuccess(this.right).map(analysisSucceeded => {
          this.actionV = this.actionBuilder.compilationFailedButAnalysisSucceeded(compilationFailed, analysisSucceeded);
          this.status = 'success';
          this.analysisResult = analysisSucceeded;
        }).orElse(() => analysisFailure(this.right).map(analysisFailed => {
          this.actionV = this.actionBuilder.compilationAndAnalysisFailed(compilationFailed, analysisFailed);
          this.status = 'failure';
          this.analysisResult = analysisFailed;
        }));
      } else {
        this.actionV = this.actionBuilder.compilationFailed(compilationFailed);
        this.status = 'failure';
      }
    }));
  }

  get compilation(): QueryCompilationResult { return this.compilationResult; }
  get compilationOpt(): Option<QueryCompilationResult> { return option(this.compilationResult); }
  get analysis(): QueryAnalysisResult {
    if (!this.analysisResult) {
      throw new Error('no analysis present');
    }
    return this.analysisResult;
  }
  get analysisOpt(): Option<QueryAnalysisResult> { return option(this.analysisResult); }
  get isSuccess(): boolean { return this.status === 'success'; }
  get isFailure(): boolean { return this.status === 'failure'; }
  get action(): A { return this.actionV; }
  get success(): TransitionSuccessType {
    if (!this.successV) {
      throw new Error('not success');
    }
    return this.successV;
  }
}
export const buildTransitionalResult = <A>(c: QueryCompilationResult, a: Option<QueryAnalysisResult>, r: TransitionResultIntoAction<A>): TransitionResultType<A> =>
  new TransitionResultType(c, a, usingSoda3EC, r);

export type TransitionSuccessType = Both<QueryCompilationSucceeded, Option<QueryAnalysisSucceeded>>;
export const buildSucceeded = (c: QueryCompilationSucceeded, a?: QueryAnalysisSucceeded) =>
  buildBoth(usingSoda3EC)(() => c, () => option(a))();
export const buildSuccessOption = (q: Query): Option<TransitionSuccessType> => compilationSuccess(q.compilationResult).map(cr => {
  const ar = analysisSuccess(q.analysisResult).orUndefined;
  return buildSucceeded(cr, ar);
});

export const createUpdateContents = (
  eitherEexpr: Either<Eexpr<FunCall, TypedSoQLFunCall>, EexprNA<TypedSoQLFunCall>>,
  otherArgs: Either<Expr[], TypedExpr[]>
) =>
  eitherEexpr.map(
    eexpr => ({
      ...eexpr.untyped,
      args: [eexpr.untyped.args[0], ...otherArgs.left]
    }),
    eexpr => ({
      ...eexpr.expr,
      args: [eexpr.expr.args[0], ...otherArgs.right]
    })
  );
